import axios from "axios";


export const axiosinstance = axios.create({
    // baseURL: process.env.REACT_APP_API,
    baseURL: 'https://b46e-2405-201-4001-b03b-293d-e236-7435-894a.ngrok.io/',
    // timeout: 15000,
    // withCredentials: true,
    headers: {
        "Content-Type": "application/json",

    },
});




export function callLinkSuccessAPI(value){
    return axiosinstance.post(`api/v1/url-shortener/full-url`,value)
}
