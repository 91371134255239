import { createSlice } from '@reduxjs/toolkit'

import {axiosinstance, callLinkSuccessAPI} from "./manager";






const linkSlice = createSlice({
    name: 'link',
    initialState:{
        link:'',
        loader:false,
        status_msg:'',
        status_code:'',
    },
    reducers: {
        callStart(state,action){
          return{
              ...state,
              loader:true
          }
        },
        linkSuccess(state,action){
            console.log(action)
            return{
                ...state,
                link:action.payload?action.payload.data.redirect_url:'',
                status_code:action.payload.status_code,
                loader:false
            }

        },
        linkFailure(state,action){
            console.log("action",action)
            if(action.payload?.data?.status_code==422){
                return{
                    ...state,
                    status_msg: "Sorry, The page you are looking for is expired.",
                    status_code: action.payload.data.status_code,
                    loader:false,
                }
            }else if(action.payload?.data?.status_code==404)
            return {
                ...state,
                status_msg: "Sorry, The page does not exist",
                status_code:action.payload.data.status_code,
                loader:false,
            }
            else{
                return {
                    ...state,
                    status_code:500,
                    status_msg:"Something went wrong!",
                    loader:false
                }


            }
        },
        clearCode(state,action){
            return{
                ...state,
                status_code: '',
                link:''
            }
        }
    },
})

export const { linkSuccess ,linkFailure,clearCode,callStart} = linkSlice.actions

export function callLinkSuccess(value){
    console.log("value",value)
    return dispatch=>{
        dispatch(callStart())
        callLinkSuccessAPI(value).then(res=>(
            dispatch(linkSuccess(res.data))
        )).catch(err=>{
            console.log(err.response)
            dispatch(linkFailure(err.response))})
    }
}


export default linkSlice.reducer


