import {combineReducers} from "redux";
import linkReducer from "./reducer"
import {configureStore} from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import history from "../history";

const reducer=combineReducers({
    linkReducer:linkReducer,
    // router:connectRouter(history)

})
const store=configureStore({reducer:reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        })})
export default store